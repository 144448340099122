#root {
    height: 100vh;
}

.kt-header {
    background: #163e84 !important;
    background: linear-gradient(to bottom, #1b5090, #163e84) !important;
}

.no-footer {
    margin-bottom: 3rem;
}

.kt-header .kt-header__top {
    height: 60px !important;
}

.form-group {
    margin-bottom: 1rem;
}

.breadcrumb-active {
    color: #2D68D1 !important;
}

a.app-card-admin,
a.app-card-admin:hover {
    color: inherit;
    text-decoration: none;
}

.app-card-admin:hover {
    opacity: 0.8;
    background-color: #f8f8fa;
    text-decoration: none;
}

.app-card-admin {
    text-decoration: none;
}

.dropdown-menu-md.show {
    position: absolute;
    top: 66px;
    right: 0;
    left: calc(100% - 310px);
}

.modal-dialog {
    margin: auto;
}

.kt-font-medium {
    font-weight: 500;
}

#searchbar {
    min-width: 20rem;
}

.kt-header__brand-logo-default {
    max-width: 10rem;
}

.kt-header .kt-header__bottom {
    height: 50px !important;
}

.kt-menu__link-text {
    font-size: 1rem !important;
}