.kt-error404-v2--enabled {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
}

.kt-error404-v2 {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
}

@media (max-width: 768px) {
    .kt-error404-v2 {
        padding: 2rem;
    }
}

.kt-error404-v2 .kt-error404-v2__content {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.kt-error404-v2 .kt-error404-v2__content .kt-error404-v2__title {
    font-size: 12rem;
    color: #ffffff;
    font-weight: 700;
}

.kt-error404-v2 .kt-error404-v2__content .kt-error404-v2__desc {
    color: #ffffff;
    font-size: 1.5rem;
}
