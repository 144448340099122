.action-checkbox-column {
    width: 0.6rem;
}

.table th,
.table td {
    vertical-align: baseline;
}

tr.selected {
    background-color: rgba(224, 224, 224, 0.5) !important;
    color: #171717 !important;
}

.kt-datatable__pager {
    margin: 0px !important;
    padding: 0.8rem !important;
    padding-left: 1rem !important;
}

.kt-portlet__head-toolbar {
    height: 38px !important;
}

#changelist-search {
    align-items: baseline;
}

.kt-portlet__head-toolbar {
    align-items: baseline;
}

th > div > a {
    color: #191b2a;
}

th.sorted > div > a {
    color: #5867dd !important;
}

#result-count-list {
    margin-left: -8rem;
    margin-top: 5px;
}

.table {
    color: #212529cc !important;
}

th > a {
    font-weight: 600 !important;
}

#search-icon {
    top: 3px;
    position: relative;
    left: -2rem;
}

.filter-icon {
    color: #00000066 !important;
    width: 1.8rem;
    padding-right: 0.5rem;
}

.filter-icon-down {
    color: #00000066 !important;
    width: 1rem;
    padding-left: 0.5rem;
}

.btn svg {
    vertical-align: middle;
    line-height: 0;
}

.datatable-col-sm {
    width: 100px;
}

.datatable-col-md {
    width: 200px;
}

.datatable-col-id {
    width: 70px;
}

.field-datatable {
    vertical-align: middle !important;
}

.kt-checkbox {
    margin-bottom: 15px !important;
}
